import image1 from "../assets/grocery-500x500-jpg.jpg";
import image2 from "../assets/shopping.webp";
import image3 from "../assets/download.jpg";
import image4 from "../assets/download1.jpg";
import image5 from "../assets/download2.jpg";
import image6 from "../assets/download3.jpg";

export const products_first = [
  {
    id: 1,
    image: image1,
    name: "Grocery",
  },
  {
    id: 2,
    image: image2,
    name: "Mobiles",
  },
  {
    id: 3,
    image: image3,
    name: "Fashion",
  },
  {
    id: 4,
    image: image4,
    name: "Electornics",
  },
  {
    id: 5,
    image: image5,
    name: "Furniture",
  },
  {
    id: 6,
    image: image6,
    name: "Travel",
  },
];
