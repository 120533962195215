import React from "react";
import "./Flip.css";
import Header from "../Header/Header";
import { products_first } from "../../data/products_first";
import Imageslider from "../Imageslider/Imageslider";
import { slider } from "../../data/slidercontent";

function Flip() {
  return (
    <>
      <div className="flip">
        <Header />
        <div className="ourproducts">
          <div className="ourproductcontainer">
            {products_first.map((program) => (
              <div className="category" key={program.id}>
                <img src={program.image} alt={program.name} />
                <li>{program.name}</li>
              </div>
            ))}
          </div>
        </div>
        <Imageslider images={slider} />
      </div>
    </>
  );
}

export default Flip;
