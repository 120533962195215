import image1 from "../assets/img1.jfif";
import image2 from "../assets/img2.jfif";
import image3 from "../assets/img3.jfif";

export const slider = [
  {
    id: 1,
    image: image1,
    title: "Hello",
  },
  {
    id: 2,
    image: image2,
    title: "Nice To Meet You",
  },
  {
    id: 3,
    image: image3,
    title: "Sarvil Here",
  },
];
