import React from "react";
import "./Header.css";
import logo from "../../assets/logo.webp";
import { Search } from "lucide-react";
import { CircleUserRound } from "lucide-react";
import { ChevronDown } from "lucide-react";
import { ShoppingCart } from "lucide-react";
import { Store } from "lucide-react";
function Header() {
  return (
    <div className="Header">
      <img src={logo} alt="flipkart" className="logo" />
      <ul className="header-menu">
        <li>
          <form>
            <div id="search">
              <span className="tool">
                <Search />
              </span>
              <input
                className="search-input"
                type="Search for Products,Brands and More"
                placeholder="search"
              />
            </div>
          </form>
        </li>
        <li>
          <span className="login">
            <div className="cricle">
              <CircleUserRound />
            </div>
            Login <ChevronDown />
          </span>
        </li>
        <li>
          <span className="kart">
            <div className="kartt">
              <ShoppingCart />
            </div>
            Cart
          </span>
        </li>
        <li>
          <span className="shop">
            <div className="shopp">
              <Store />
            </div>
            Become a Seller
          </span>
        </li>
      </ul>
    </div>
  );
}

export default Header;
