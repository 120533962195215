import React, { useState } from "react";
import "./Imageslider.css";
import { slider } from "../../data/slidercontent";

function Imageslider({ images }) {
  const [current, setCurrent] = useState(0);
  const slideRight = () => {
    setCurrent(current === images.length - 1 ? 0 : current + 1);
  };
  const slideLeft = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  };
  console.log(current);
  return (
    <div className="Imageslider">
      <div className="carousel">
        <div className="carousel_wrapper">
          {slider.map((image, index) => {
            return (
              <div
                key={index}
                className={
                  index === current
                    ? "carousel_card carousel_card-active"
                    : "carousel_card"
                }
              >
                <img className="card_image" src={image.image} alt="" />;
                <div className="card_overlay">
                  <h2 className="card_title">{image.title}</h2>
                </div>
              </div>
            );
          })}
          <div className="carousel_arrow_left" onClick={slideLeft}>
            &lsaquo;
          </div>
          <div className="carousel_arrow_right" onClick={slideRight}>
            &rsaquo;
          </div>
          {/* <div className="carousel_pagination">
            {images.map((_, index) => {
              return (
                <div
                  key={index}
                  className={
                    index === current
                      ? "carousel_card carousel_card-active"
                      : "carousel_card"
                  }
                ></div>
              );
            })} */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Imageslider;
