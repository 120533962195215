import "./App.css";
import Flip from "./content/Flip/Flip";

function App() {
  return (
    <div className="App">
      <Flip />
    </div>
  );
}

export default App;
